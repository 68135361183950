<template>
  <div>
    <tgl-card-header-mobile
      >Monitoreo de últimos registros</tgl-card-header-mobile
    >
    <tgl-card-content-mobile>
      <div class="row pt-5 pb-5 pr-1 pl-1" v-if="deviceDisplayers">
        <template v-for="s in numberSlots">
          <div :key="s.id" class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <number :deviceSlot="s" />
          </div>
        </template>
        <template v-for="s in batterySlots">
          <div :key="s.id" class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <battery :deviceSlot="s" />
          </div>
        </template>
        <template v-for="s in signalSlots">
          <div :key="s.id" class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <signal :deviceSlot="s" />
          </div>
        </template>
      </div>
    </tgl-card-content-mobile>
  </div>
</template>
<script>
import Battery from "@/components/Monitoring/Battery";
import Signal from "@/components/Monitoring/Signal";
import Number from "@/components/Monitoring/Number";
import TglCardContentMobile from "@/components/Mobile/Cards/TglCardContentMobile.vue";
import TglCardHeaderMobile from "@/components/Mobile/Cards/TglCardHeaderMobile.vue";
export default {
  name: "mobile-latest",
  components: {
    Battery,
    Signal,
    Number,
    TglCardContentMobile,
    TglCardHeaderMobile,
  },
  data: () => ({
    deviceDisplayers: null,
    batterySlots: [],
    signalSlots: [],
    numberSlots: [],
  }),
  methods: {
    getDeviceDisplayers() {
      this.$store
        .dispatch("displayers/getDeviceDisplayers", this.$route.params.device)
        .then((data) => {
          this.deviceDisplayers = data;
          this.divideDisplayers();
        });
    },
    divideDisplayers() {
      this.deviceDisplayers.forEach((displayer) => {
        switch (displayer.displayModeId) {
          case 6:
            this.batterySlots.push(displayer);
            break;
          case 7:
            this.signalSlots.push(displayer);
            break;
          case 5:
            this.numberSlots.push(displayer);
            break;
          default:
            break;
        }
      });
    },
  },
  mounted() {
    this.getDeviceDisplayers();
  },
};
</script>

<style>
</style>